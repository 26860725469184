<!--HomeImg:首页图片 2024/07/20 10:50:12 huangmx-->

<template>
  <div class="home-img">
    <a :href="origin + '/pz/post/' + item.id" target="_blank" class="left-div">
      <img class="animate-img img-detail" v-if="item.coverList && item.coverList.length > 0" :src="item.coverList[0]" />
      <div class="title" :class="{ 'big-size': size === 'big', 'small-size': size === 'small' }">
        <div>{{ item.title }}</div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: 'HomeImg',
  props: {
    size: {
      type: String,
      default: 'big'
    },
    item: {
      type: Object,
      default: function () {
        return {
          id: 1,
          title: '【PIP】胜地历险记全卡预览（145/145）',
          cover: 'https://pic.iyingdi.com/post/cover/2024/06/13/1071442/07da0c5f-6d0a-4522-be64-8a46b2d55d99_w_185_h_102.jpg?imageMogr2/format/jpg|imageMogr2/thumbnail/600x|imageMogr2/quality/60'
        }
      }
    }
  },
  data () {
    return {
      origin: window.location.origin
    }
  }
}
</script>

<style scoped>
.home-img {
  width: 100%;
  height: 100%;
}

.left-div {
  display: inline-block;
  height: 100%;
  border-radius: 14px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.img-detail {
  height: 100%;
}

.left-div .title {
  width: 100%;
  background: linear-gradient(180deg, transparent, #000);
  border-radius: 0 0 14px 14px;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  position: absolute;
  bottom: 0;
}

.left-div .big-size {
  height: 107px;
}

.left-div .small-size {
  height: 79px;
}

.left-div .title>div {
  --tw-text-opacity: 1;
  color: white;
}

.left-div .big-size>div {
  width: 322px;
  font-size: 20px;
  line-height: 28px;
  position: absolute;
  bottom: 30px;
}

.left-div .small-size>div {
  width: 200px;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  bottom: 10px;
}
</style>
