<template>
  <div class="home-index page-center-content" @scroll="handleScroll">
    <!-- banner -->
    <div class="banner">
      <recommend-banner :list="recommendArticles"></recommend-banner>
    </div>
    <!-- content -->
    <div class="list-content" v-infinite-scroll="getArtiles" :infinite-scroll-immediate=false
      :infinite-scroll-distance=30 :infinite-scroll-disabled="!loadMore">
      <div class="list-content-left">
        <div v-for="item in articleList" :key="'con' + item.id">
          <feed-list-common v-if="item.type === 'POST'" :item="item"></feed-list-common>
          <feed-list-rich v-else :item="item"></feed-list-rich>
        </div>
      </div>
      <div class="list-content-right">
        <div class="sticky-top-area" :style="{ position: fixed ? 'fixed' : 'static' }">
          <div class="popular-container">
            <div class="popular-title">
              <h3>{{ $t('hotArticles') }}</h3>
            </div>
            <ul class="popular-list">
              <li class="list-item" v-for="(item, index) in hotArticles" :key="'hot' + item.id">
                <i class="num" :class="'num_' + (index + 1)">{{ index + 1 }}.</i>
                <a :href="'/pz/post/' + item.id" class="feed-title" target="_blank">
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FeedListCommon from '../components/FeedListCommon.vue'
import FeedListRich from '../components/FeedListRich.vue'
import RecommendBanner from '../components/RecommendBanner.vue'
import { articleApi } from '@/utils/api'
export default {
  components: { FeedListCommon, FeedListRich, RecommendBanner },
  name: 'index',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      fixed: false,
      currentPage: 1,
      isloading: false,
      loadMore: true,
      recommendArticles: [], // 推荐文章
      hotArticles: [], // 热榜
      articleList: [], // 文章列表
      articleInteractions: [] // 文章互动
    }
  },
  created () {
    // 监听window对象的scroll事件
    window.addEventListener('scroll', this.handleScroll)
    this.init()
  },
  mounted () {
    // 更新Dom
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    init: function () {
      this.getSpecileArticles()
      this.getArtiles()
    },
    /**
     * 特殊文章列表
     */
    getSpecileArticles: function () {
      const params = {
        items: [
          {
            articleSpecialType: 'TOP',
            num: 4
          },
          {
            articleSpecialType: 'HOT',
            num: 10
          }
        ]
      }
      articleApi.getSpecileArticles(params).then(resp => {
        console.log('getRecommendArticles', resp)
        if (resp.httpStatus === 200) {
          for (let i = 0; i < resp.data.length; i++) {
            if (resp.data[i].specialType === 'TOP') {
              this.recommendArticles = resp.data.find(item => item.specialType === 'TOP').articles
            }
            this.hotArticles = resp.data.find(item => item.specialType === 'HOT').articles
          }
        }
      })
    },
    /**
     * 文章列表
     */
    getArtiles: function () {
      if (this.isloading) {
        return
      }
      this.isloading = true
      articleApi.getArticleListByClassificationId({
        pageSize: 20,
        currentPage: this.currentPage
      }).then(resp => {
        this.isloading = false
        if (resp.httpStatus === 200) {
          this.currentPage++
          this.loadMore = resp.currentPage * resp.pageSize < resp.totalSize
          this.articleList = this.articleList.concat(resp.data)
          console.log('article list', this.articleList)
          const articleIds = this.articleList.map(item => item.id)
          this.getArticleInteractions(articleIds.join(','))
        }
      }).catch(err => {
        console.log('getArticleListByClassificationId error', err)
        this.isloading = false
      })
    },
    /**
     *  文章互动数据
     * @param {文章 id} articleIds 用逗号分割
     */
    getArticleInteractions: function (articleIds) {
      articleApi.getArticleInteractions({
        articleIds: articleIds
      }).then(resp => {
        if (resp.httpStatus === 200) {
          this.articleInteractions = resp.data
          // 为 articles 追加 互动数据
          const interactionMap = new Map(this.articleInteractions.map(interaction => [interaction.articleId, interaction]))
          this.articleList = this.articleList.map(article => {
            const interactionData = interactionMap.get(article.id)
            if (interactionData) {
              // 如果找到互动数据，则追加 likeNum 和 commentNum
              return {
                ...article,
                likeNum: interactionData.likeNum,
                commentNum: interactionData.commentNum
              }
            } else {
              // 如果没有找到互动数据，则保留原有文章数据
              return article
            }
          })
          console.log('article interactions', this.articleInteractions)
        }
      })
    },
    handleScroll: function () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      this.fixed = scrollTop > 400
    }
  }
}
</script>

<style scoped>
.home-index {
  padding-top: 30px;
  height: 100%;
  overflow: auto;
}

.banner {
  height: 325px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.list-content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.list-content-left {
  width: 778px;
  min-height: 1300px;
}

.list-content-right {
  width: 282px;
  min-height: 300px;
}

.popular-container {
  background-color: white;
  border-radius: 14px;
  font-size: 14px;
  line-height: 20px;
  line-height: 1;
  padding: 20px;
  position: relative;
  color: rgb(82, 82, 82);
  min-height: 200px;
}

.popular-container .popular-title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  color: rgb(50, 50, 50);
}

.popular-container .popular-title>h3 {
  font-weight: 700;
  height: 16px;
  line-height: 1;
}

.popular-container .popular-list {
  padding-top: 20px;
  position: relative;
}

.popular-list .list-item {
  border-bottom: 1px dashed rgb(242, 242, 242);
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
  padding-bottom: 10px;
  color: rgba(82, 82, 82);
}

.list-item:last-of-type {
  border-width: 0;
}

.popular-list .list-item .num {
  line-height: 24px;
  font-style: normal;
  flex: 0 0 22px;
}

.num_0,
.num_1 {
  font-weight: bolder;
  color: #f56c6c;
  font-style: italic !important;
}

.num_2 {
  font-weight: bolder;
  color: #e6a23c;
  font-style: italic !important;
}

.num_3 {
  font-weight: bolder;
  color: #67c23a;
  font-style: italic !important;
}

.popular-list .list-item .feed-title {
  flex: 1 1 0%;
  line-height: 24px;
  letter-spacing: 0.7px;
}
</style>
