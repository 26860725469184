<!--RecommendBanner:推荐 banner 2024/07/20 19:09:16 huangmx-->

<template>
  <div class="recommend-banner" v-if="list.length > 0">
    <div class="banner-left">
      <home-img :item="list[0]" :size="'big'"></home-img>
    </div>
    <div class="banner-right">
      <div class="banner-right-top">
        <div class="left right-top-item" v-if="list.length > 1">
          <home-img :item="list[1]" :size="'small'"></home-img>
        </div>
        <div class="right right-top-item" v-if="list.length > 2">
          <home-img :item="list[2]" :size="'small'"></home-img>
        </div>
      </div>
      <div class="banner-right-bottom" v-if="list.length > 3">
        <a :href="origin + '/pz/post/' + list[3].id" class="right-bottom-div" target="_blank">
          <div>
            <div class="user-info">
              <head-img class="user-img" :user="list[3].authorInfo" :border="false" :size="'26px'"></head-img>
              <span>{{ list[3].authorInfo.name }}</span>
            </div>
            <div class="title">{{ list[3].title }}</div>
            <div class="desc">{{ list[3].summary }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import HomeImg from '@/views/home/components/HomeImg'
import HeadImg from '@/views/components/HeadImg'
export default {
  name: 'RecommendBanner',
  components: { HomeImg, HeadImg },
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      origin: window.location.origin
    }
  },
  created () {

  },
  mounted () {
    // 更新Dom
  },
  methods: {
  }
}
</script>

<style scoped>
.recommend-banner {
  height: 325px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.banner-left {
  width: 570px;
  height: 100%;
  border-radius: 14px;
  overflow: hidden;
}

.banner-right {
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.banner-right-top {
  width: 100%;
  height: 176px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.right-top-item {
  width: 244px;
  height: 100%;
  border-radius: 14px;
}

.banner-right-bottom {
  width: 100%;
  height: 139px;
  display: flex;
  justify-content: space-between;
  border-radius: 14px;
  overflow: hidden;
}

.banner-right-bottom .right-bottom-div {
  width: 500px;
  height: 139px;
  background-color: white;
  border-radius: 14px;
  cursor: pointer;
  padding: 20px;
}

.banner-right-bottom .right-bottom-div .user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(120, 120, 120);
}

.banner-right-bottom .right-bottom-div .user-info .user-img {
  margin-right: 10px;
}

.banner-right-bottom .right-bottom-div .title {
  width: 100%;
  margin-top: 10px;
  font-weight: 700;
  overflow: hidden;
  color: rgb(82, 82, 82);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.banner-right-bottom .right-bottom-div .desc {
  font-size: 14px;
  line-height: 20px;
  color: rgb(185, 185, 185);
  word-break: break-all;
  height: 40px;
  overflow: hidden;
}
</style>
